/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Paper,
  Typography,
  Breadcrumbs,
  Link,
  Grid,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { formatDate } from '@ink-ai/portal/common/utils';
import { RootState } from '@ink-ai/portal/reducers';
import { CreateReferenceStoreResponseDtoStatusEnum } from '@ink-ai/insight-service-sdk';
import { getReferenceStoreById } from '@ink-ai/portal/reducers/reference-store';

export const ReferenceStoreDetail: React.FC = () => {
  const { t } = useTranslation();
  const formatter = new Intl.NumberFormat('en-US');
  const { id: referenceStoreUuid } = useParams<{ id: string }>();
  const dispatch = useDispatch<any>();
  const referenceStore = useSelector(
    (state: RootState) => state.referenceStore,
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (referenceStoreUuid) {
      dispatch(getReferenceStoreById(referenceStoreUuid));
    }
  }, [dispatch, referenceStoreUuid]);

  return (
    <Container maxWidth="lg" sx={{ mt: 1, mb: 1 }}>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Link
          color="inherit"
          onClick={() => navigate('/')}
          sx={{ textDecoration: 'none', cursor: 'pointer' }}
        >
          {t('referenceStore.navigation.home')}
        </Link>
        <Link
          color="inherit"
          onClick={() => navigate('/reference-store')}
          sx={{ textDecoration: 'none', cursor: 'pointer' }}
        >
          {t('referenceStore.navigation.referenceStore')}
        </Link>
        <Typography color="textPrimary">
          {referenceStore.selectedReferenceStore?.name ||
            t('referenceStore.navigation.loading')}
        </Typography>
      </Breadcrumbs>

      <Paper sx={{ p: 3, display: 'flex', flexDirection: 'column' }}>
        <Typography variant="h5" sx={{ textAlign: 'left' }}>
          {referenceStore.selectedReferenceStore?.name ||
            t('referenceStore.navigation.loading')}
        </Typography>
        <Typography variant="subtitle1" sx={{ textAlign: 'left', mb: 2 }}>
          {t('referenceStore.detail.settings')}
        </Typography>

        <Grid container spacing={3} sx={{ mb: 3 }}>
          <Grid item xs={4}>
            <Typography
              variant="body2"
              sx={{ fontWeight: 'bold', textAlign: 'left' }}
            >
              {t('referenceStore.detail.fields.terms')}
            </Typography>
            <Typography
              variant="body2"
              sx={{ textAlign: 'left', color: 'gray' }}
            >
              {referenceStore.selectedReferenceStore?.status ===
              CreateReferenceStoreResponseDtoStatusEnum.Ready
                ? formatter.format(
                    referenceStore.selectedReferenceStore?.itemCount,
                  )
                : t('referenceStore.detail.values.na')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="body2"
              sx={{ fontWeight: 'bold', textAlign: 'left' }}
            >
              {t('referenceStore.detail.fields.languages')}
            </Typography>
            <Typography
              variant="body2"
              sx={{ textAlign: 'left', color: 'gray' }}
            >
              {referenceStore.selectedReferenceStore?.status ===
              CreateReferenceStoreResponseDtoStatusEnum.Ready
                ? referenceStore.selectedReferenceStore?.languages.join(', ')
                : t('referenceStore.detail.values.na')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="body2"
              sx={{ fontWeight: 'bold', textAlign: 'left' }}
            >
              {t('referenceStore.detail.fields.status')}
            </Typography>
            <Typography
              variant="body2"
              sx={{ textAlign: 'left', color: 'gray' }}
            >
              {referenceStore.selectedReferenceStore?.status
                ?.charAt(0)
                .toUpperCase() +
                referenceStore.selectedReferenceStore?.status
                  ?.slice(1)
                  .toLowerCase()}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="body2"
              sx={{ fontWeight: 'bold', textAlign: 'left' }}
            >
              {t('referenceStore.detail.fields.storage')}
            </Typography>
            <Typography
              variant="body2"
              sx={{ textAlign: 'left', color: 'gray' }}
            >
              {t('referenceStore.detail.values.openSearch')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="body2"
              sx={{ fontWeight: 'bold', textAlign: 'left' }}
            >
              {t('referenceStore.detail.fields.indexName')}
            </Typography>
            <Typography
              variant="body2"
              sx={{ textAlign: 'left', color: 'gray' }}
            >
              {referenceStore.selectedReferenceStore?.indexName ||
                t('referenceStore.detail.values.na')}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography
              variant="body2"
              sx={{ fontWeight: 'bold', textAlign: 'left' }}
            >
              {t('referenceStore.detail.fields.updatedAt')}
            </Typography>
            <Typography
              variant="body2"
              sx={{ textAlign: 'left', color: 'gray' }}
            >
              {formatDate(referenceStore.selectedReferenceStore?.updatedAt)}
            </Typography>
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>
      </Paper>
    </Container>
  );
};
