/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Menu,
  MenuItem,
  Divider,
  Breadcrumbs,
  Link,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useNavigate } from 'react-router-dom';
import {
  ReferenceStoreDto,
  ReferenceStoreDtoStatusEnum,
} from '@ink-ai/insight-service-sdk';
import { RootState } from '@ink-ai/portal/reducers';
import { LoadingButton } from '@mui/lab';
import { ReferenceStoreTable } from '../../components/reference-store/ReferenceStoreListTable';
import {
  deleteReferenceStores,
  handleDownload,
  listReferenceStores,
  referenceStoreActions,
} from '@ink-ai/portal/reducers/reference-store';

export const ReferenceStore: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();
  const referenceStore = useSelector(
    (state: RootState) => state.referenceStore,
  );
  const navigate = useNavigate();
  const actionsButtonRef = useRef<HTMLButtonElement>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedReferenceStores, setSelectedReferenceStores] = useState<
    string[]
  >([]);
  const [page, setPage] = useState(0);
  const [menuLoading, setMenuLoading] = useState(false);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    dispatch(referenceStoreActions.setLoading(true));
    handleMenuClose();
    dispatch(deleteReferenceStores(selectedReferenceStores));
    setSelectedReferenceStores([]);
  };

  const handleExportCsv = async () => {
    setMenuLoading(true);

    if (selectedReferenceStores.length === 1) {
      try {
        await handleDownload(selectedReferenceStores[0]);
      } catch (error) {
        console.error('Error exporting referenceStore:', error);
      } finally {
        setMenuLoading(false);
      }
    } else {
      setMenuLoading(false);
    }
    handleMenuClose();
  };

  const handleUpdate = () => {
    if (selectedReferenceStores.length === 1) {
      const selectedReferenceStore = referenceStore.referenceStores.find(
        (g) => g.uuid === selectedReferenceStores[0],
      );
      if (selectedReferenceStore) {
        navigate('/reference-store/import', {
          state: {
            referenceStoreUuid: selectedReferenceStore.uuid,
            referenceStoreName: selectedReferenceStore.name,
            referenceStoreDescription: selectedReferenceStore.desc,
          },
        });
      }
    }
    handleMenuClose();
  };

  const handleNavigate = (referenceStore: ReferenceStoreDto) => {
    dispatch(referenceStoreActions.setSelectedReferenceStore(referenceStore));
    navigate(`/reference-store/${referenceStore.uuid}`);
  };

  const handleCsvExportDisabled = () => {
    return selectedReferenceStores.length !== 1;
  };

  const handleUpdateDisabled = () => {
    if (selectedReferenceStores.length !== 1) return true;
    const selectedReferenceStore = referenceStore.referenceStores.find(
      (g) => g.uuid === selectedReferenceStores[0],
    );
    return (
      selectedReferenceStore?.status === ReferenceStoreDtoStatusEnum.Importing
    );
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 1, mb: 1 }}>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Link
          color="inherit"
          onClick={() => navigate('/')}
          sx={{ textDecoration: 'none', cursor: 'pointer' }}
        >
          {t('referenceStore.navigation.home')}
        </Link>
        <Typography color="textPrimary">
          {t('referenceStore.navigation.referenceStore')}
        </Typography>
      </Breadcrumbs>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        <Typography variant="h5" sx={{ textAlign: 'left', mb: 2 }}>
          {t('referenceStore.title')}
        </Typography>
        <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }}>
          <TextField
            label={t('referenceStore.search.label')}
            variant="outlined"
            size="small"
            value={searchTerm}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleSearchChange}
          />
          <Box>
            <Button
              sx={{ mr: 1 }}
              aria-label="refresh"
              variant="outlined"
              onClick={() => dispatch(listReferenceStores())}
            >
              <RefreshIcon />
            </Button>
            <LoadingButton
              ref={actionsButtonRef}
              color="info"
              onClick={handleMenuOpen}
              endIcon={<ArrowDropDownIcon />}
              variant="outlined"
              disabled={selectedReferenceStores.length === 0}
              loading={menuLoading}
            >
              {t('referenceStore.actions.button')}
            </LoadingButton>
            <Button
              variant="contained"
              color="primary"
              sx={{ ml: 1 }}
              onClick={() => navigate('/reference-store/import')}
            >
              {t('referenceStore.actions.import')}
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              slotProps={{
                paper: {
                  style: {
                    minWidth: actionsButtonRef.current?.offsetWidth,
                  },
                },
              }}
            >
              <MenuItem
                onClick={handleExportCsv}
                disabled={handleCsvExportDisabled() || menuLoading}
              >
                {t('referenceStore.actions.exportCsv')}
              </MenuItem>
              <MenuItem
                onClick={handleUpdate}
                disabled={handleUpdateDisabled() || menuLoading}
              >
                {t('referenceStore.actions.update')}
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={handleDelete}
                disabled={selectedReferenceStores.length === 0 || menuLoading}
              >
                {t('referenceStore.actions.delete')}
              </MenuItem>
            </Menu>
          </Box>
        </Box>
        <ReferenceStoreTable
          selectedReferenceStores={selectedReferenceStores}
          onSelectedReferenceStoresChange={setSelectedReferenceStores}
          searchTerm={searchTerm}
          onNavigate={handleNavigate}
          page={page}
          onPageChange={setPage}
        />
      </Paper>
    </Container>
  );
};
