/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React, { useRef, useEffect, useState } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { MainListItems } from '../components/ListItems';
import { Outlet } from 'react-router-dom';
import { PluginWindow } from '../components/plugin/PluginWindow';
import { Stack, Button } from '@mui/material';
import { NotificationSnackbar } from '../components/NotificationBar';
import { SocketHandler } from '@ink-ai/portal/common/socket/SocketHandler';
import { Logout } from '../components/user/Logout';
import { RootState } from '../../reducers';
import { useDispatch, useSelector } from 'react-redux';
import { aiAssistant } from '../../reducers/ai-assistant';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { app } from '@ink-ai/portal/reducers/app';
import { ThinkingButton } from '@ink-ai/portal/common/components/ai-assistant/Compose/ThinkingDialog';
import { useTranslation } from 'react-i18next';

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const defaultTheme = createTheme();

export const Home = () => {
  const { t } = useTranslation();
  const aiAssistantState = useSelector((state: RootState) => state.aiAssistant);
  const appState = useSelector((state: RootState) => state.app);
  const dispatch = useDispatch<any>();
  const [snackbarTop, setSnackbarTop] = useState(0);
  const appBarRef = useRef<HTMLDivElement>(null);

  const toggleDrawer = () => {
    dispatch(app.actions.setLeftDrawerOpen(!appState.leftDrawerOpen));
  };

  const togglePluginWindow = () => {
    dispatch(aiAssistant.actions.toggleAssistant(!aiAssistantState.open));
  };

  useEffect(() => {
    if (appBarRef.current) {
      const appBarHeight = appBarRef.current.clientHeight;
      setSnackbarTop(appBarHeight + 8);
    }
  }, [appBarRef]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <SocketHandler />
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
          position="absolute"
          open={appState.leftDrawerOpen}
          ref={appBarRef}
        >
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(appState.leftDrawerOpen && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              {t('solutionName')}
            </Typography>
            {aiAssistantState.showIcon && (
              <Button color="inherit" onClick={togglePluginWindow} size="large">
                <AutoAwesomeIcon className="mr-2" />
                AI
              </Button>
            )}
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={appState.leftDrawerOpen}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <Box
            sx={{
              height: 'calc(100vh - 64px - 53px)', // 64px is the height of the AppBar, 53px is the logout button
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
          >
            <Stack component="nav" spacing={0.5} sx={{ px: 2 }}>
              <MainListItems />
            </Stack>
          </Box>
          <Stack
            component="nav"
            spacing={0.5}
            sx={{ position: 'absolute', width: '100%', bottom: 0 }}
          >
            <Divider />
            <Logout />
          </Stack>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Outlet />
              </Grid>
            </Grid>
          </Container>
        </Box>
        <PluginWindow open={aiAssistantState.open} />
      </Box>
      <NotificationSnackbar
        style={{ position: 'absolute', top: snackbarTop, right: 20 }}
      />
      <ThinkingButton />
    </ThemeProvider>
  );
};
