/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { getAppParam, storeAppParam } from '../common/localStorageHelper';
import { AlertColor } from '@mui/material';

/** The MIH deployment edition */
export enum AppEdition {
  /** Amazon hosted MIH, enable amazon sso */
  AMZN = 'AMZN',
  /** Custom deployment, only show cognito button */
  CUSTOM = 'CUSTOM',
}

export interface AppParam {
  ink_ai_edition: AppEdition;
  ink_ai_api_endpoint: string;
  ink_ai_ws_endpoint: string;
  cognito_host: string;
  cognito_client_id: string;
  coedit_enabled: boolean;
  authorization_endpoint?: string;
}

export type AppState = {
  appParam: AppParam;
  addInHost: string;
  isInitializing: boolean;
  globalMessageOpen: boolean;
  globalMessageStatus?: AlertColor;
  globalMessage?: string;
  navigationPath: string;
  leftDrawerOpen: boolean;
};

const getInitState = (): AppState => {
  const cachedAppParam = getAppParam();
  return {
    appParam: {
      ink_ai_edition: AppEdition.CUSTOM,
      ink_ai_api_endpoint: process.env['SERVICE_HOST'],
      ink_ai_ws_endpoint: process.env['APIG_URL'],
      cognito_host: process.env['AMAZON_COGNITO_ISSUER'],
      cognito_client_id: process.env['AMAZON_COGNITO_CLIENT_ID'],
      coedit_enabled: true,
      ...cachedAppParam,
    },
    addInHost: location.origin,
    isInitializing: true,
    globalMessageOpen: false,
    navigationPath: '',
    leftDrawerOpen: true,
  };
};

export const fetchAppParam = createAsyncThunk('app/fetchAppParam', async () => {
  const cachedAppParam = getAppParam();
  if (cachedAppParam) {
    return cachedAppParam;
  }
  const response = await axios.get<AppParam>(
    `/ink-ai-exports.json?t=${Date.now()}`,
  );
  const appParam = response.data;
  // auto detect OIDC authorization endpoint
  if (!appParam.authorization_endpoint) {
    const { data: oidcConfig } = await axios.get<any>(
      `${appParam.cognito_host}/.well-known/openid-configuration`,
    );
    appParam.authorization_endpoint = oidcConfig.authorization_endpoint;
  }
  storeAppParam(appParam);
  return appParam;
});

export const app = createSlice({
  name: 'app',
  initialState: getInitState(),
  reducers: {
    setLeftDrawerOpen(state, { payload }: PayloadAction<boolean>) {
      state.leftDrawerOpen = payload;
    },
    setApp(_state, { payload }: PayloadAction<AppState>) {
      console.log('setAppStore', payload);
      return payload;
    },
    setGlobalMessage(
      state,
      { payload }: PayloadAction<{ message: string; status: AlertColor }>,
    ) {
      state.globalMessageOpen = true;
      state.globalMessage = payload.message;
      state.globalMessageStatus = payload.status;
    },
    closeGlobalMessage(state) {
      state.globalMessageOpen = false;
    },
    setNavigationPath(state, { payload }: PayloadAction<string>) {
      state.navigationPath = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAppParam.pending, (state) => {
      state.isInitializing = true;
    });
    builder.addCase(fetchAppParam.fulfilled, (state, { payload }) => {
      state.appParam = {
        ...state.appParam,
        ...payload,
      };
      state.isInitializing = false;
    });
    builder.addCase(fetchAppParam.rejected, (state) => {
      state.isInitializing = false;
    });
  },
});
