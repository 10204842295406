/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Slate, Editable, withReact, ReactEditor } from 'slate-react';
import { createEditor, Descendant, BaseEditor } from 'slate';
import { Box, InputLabel, Typography } from '@mui/material';
import { RootState } from '@ink-ai/portal/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { writingInstructionActions } from '@ink-ai/portal/reducers/writing-instruction';

type CustomElement = { type: 'paragraph'; children: CustomText[] };
type CustomText = { text: string; blueText?: boolean };

declare module 'slate' {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

const RichTextEditor: React.FC = () => {
  const { t } = useTranslation();
  const editor = useMemo(() => withReact(createEditor()), []);
  const [focused, setFocused] = useState(false);
  const editorRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const instructionState = useSelector(
    (state: RootState) => state.writingInstruction,
  );

  const [value, setValue] = useState<Descendant[]>(
    instructionState.userInstructionObj,
  );
  const [editorKey, setEditorKey] = useState(0);

  // Reload Slate editor when userInstructionObj changes
  useEffect(() => {
    setValue(instructionState.userInstructionObj);
    setEditorKey((prevKey) => prevKey + 1);
  }, [instructionState.userInstructionObj]);

  const handleLabelClick = () => {
    if (editorRef.current) {
      editorRef.current.focus();
    }
  };

  const decorate = useCallback(([node, path]) => {
    const ranges = [];
    if ('text' in node) {
      const text = node.text;
      const regex = /\{([^}]+)\}/g;
      let match;
      while ((match = regex.exec(text)) !== null) {
        const start = match.index;
        const end = start + match[0].length;
        ranges.push({
          anchor: { path, offset: start },
          focus: { path, offset: end },
          blueText: true,
        });
      }
    }
    return ranges;
  }, []);

  const renderLeaf = useCallback(({ attributes, children, leaf }) => {
    return (
      <span
        {...attributes}
        style={{ color: leaf.blueText ? '#1976d2' : 'inherit' }}
      >
        {children}
      </span>
    );
  }, []);

  return (
    <Box>
      {/* Editor Container */}
      <Box
        onClick={handleLabelClick}
        className={`relative border rounded transition-colors ${
          focused ? 'border-blue-500' : 'border-gray-300'
        } hover:border-black`}
        sx={{
          textAlign: 'left',
          '&:hover': { borderColor: 'black' },
          position: 'relative',
          borderRadius: '6px',
          borderWidth: '1px',
          borderColor: focused ? '#1976d2' : '#c4c4c4',
          transition: 'border-color 0.3s ease',
          '&.focused::after': {
            content: '""',
            position: 'absolute',
            top: '-2px',
            right: '-2px',
            bottom: '-2px',
            left: '-2px',
            border: '2px solid #1976d2',
            borderRadius: '6px',
            pointerEvents: 'none',
          },
        }}
      >
        {/* Input Label */}
        <InputLabel
          className={`absolute -top-3 left-4 bg-white text-gray-500 text-sm transition-colors ${
            focused ? 'text-blue-500' : ''
          }`}
          shrink
          sx={{
            position: 'absolute',
            top: '-8px',
            left: '14px',
            zIndex: 99,
            backgroundColor: 'white',
            color: focused ? '#1976d2' : '#757575',
            fontSize: '0.875rem',
            transition: 'color 0.3s ease',
            pointerEvents: 'none',
          }}
        >
          {t('writingInstructions.editor.richText.label')}
        </InputLabel>

        {/* Slate Editor */}
        <Slate
          key={editorKey}
          editor={editor}
          initialValue={value}
          onChange={(newValue) => {
            setValue(newValue);
            dispatch(writingInstructionActions.changeUserInstruction(newValue));
          }}
        >
          <Editable
            ref={editorRef}
            decorate={decorate}
            renderLeaf={renderLeaf}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            placeholder={t('writingInstructions.editor.richText.placeholder')}
            spellCheck
            className="px-4 py-3 w-full flow-root"
            sx={{
              padding: '8px 12px',
              width: '100%',
              fontFamily: 'inherit',
              fontSize: '1rem',
              lineHeight: 1.5,
              outline: 'none',
              border: 'none',
              marginTop: '-10px',
            }}
          />
        </Slate>
      </Box>

      {/* Helper Text */}
      <Typography
        textAlign="left"
        variant="caption"
        display="block"
        gutterBottom
        marginLeft={2}
        sx={{ color: 'rgba(0, 0, 0, 0.6)' }}
      >
        {t('writingInstructions.editor.richText.helper')}
      </Typography>
    </Box>
  );
};

export default RichTextEditor;
