/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAsyncData } from '../../common/hooks/useAsyncData';
import { getApi } from '../../common/requestHelper';
import {
  WritingRuleApi,
  WritingRuleCollectionResponseDto,
} from '@ink-ai/insight-service-sdk';
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Divider,
  Menu,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { EllipsisCell } from '@ink-ai/portal/common/components/paginated-table/EllipsisCell';
import { formatDate } from '@ink-ai/portal/common/utils';
import { PaginatedTable } from '@ink-ai/portal/common/components/paginated-table/PaginatedTable';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { debounce } from 'lodash-es';
import { LoadingButton } from '@mui/lab';

const useWritingRuleColumns = () => {
  const { t } = useTranslation();
  return [
    {
      id: 'title',
      label: t('writingRules.table.title'),
      render: ({ title, id }: WritingRuleCollectionResponseDto) => (
        <EllipsisCell
          content={
            <Link
              className="cursor-pointer text-blue-600"
              to={`/writing-rule/${id}/edit`}
            >
              {title}
            </Link>
          }
          tooltip={title}
        />
      ),
    },
    {
      id: 'description',
      label: t('writingRules.table.description'),
      render: ({ description }: WritingRuleCollectionResponseDto) => (
        <Typography>{description}</Typography>
      ),
    },
    {
      id: 'author',
      label: t('writingRules.table.author'),
      render: ({ creator }: WritingRuleCollectionResponseDto) => (
        <Typography>{creator.email ?? creator.name}</Typography>
      ),
    },
    {
      id: 'createdAt',
      label: t('writingRules.table.createdAt'),
      render: ({ createdAt }: WritingRuleCollectionResponseDto) =>
        formatDate(createdAt),
    },
    {
      id: 'updatedAt',
      label: t('writingRules.table.updatedAt'),
      render: ({ updatedAt }: WritingRuleCollectionResponseDto) =>
        formatDate(updatedAt),
    },
  ];
};

export const WritingRuleList = () => {
  const { t } = useTranslation();
  const writingRuleColumns = useWritingRuleColumns();
  const [page, setPage] = useState(0);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const actionsButtonRef = useRef<HTMLButtonElement>(null);
  const [search, setSearch] = useState('');
  const [tempSearch, setTempSearch] = useState('');
  const [menuLoading, setMenuLoading] = useState(false);
  const navigate = useNavigate();
  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const { data, isLoadingData, reloadData } = useAsyncData(async () => {
    const api = await getApi(WritingRuleApi);
    return api.query({
      pagination: {
        take: rowsPerPage,
        skip: page * rowsPerPage,
      },
      search,
    });
  }, [page, rowsPerPage, search]);
  const deleteWritingRuleCollection = async () => {
    if (!selectedId) {
      return;
    }
    const api = await getApi(WritingRuleApi);
    await api.deleteRuleCollection(selectedId);
  };

  const handleDelete = async () => {
    setMenuLoading(true);
    try {
      await deleteWritingRuleCollection();
      reloadData();
      handleMenuClose();
      setSelectedId(null);
    } catch (error) {
      console.error(error);
    } finally {
      setMenuLoading(false);
    }
  };

  const debouncedSetSearch = useCallback(debounce(setSearch, 500), [setSearch]);

  useEffect(() => {
    debouncedSetSearch(tempSearch);
  }, [tempSearch, debouncedSetSearch]);

  return (
    <Container maxWidth="lg" sx={{ mt: 1, mb: 1 }}>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link to="/">{t('writingRules.navigation.home')}</Link>
        <Typography color="textPrimary">
          {t('writingRules.navigation.writingRule')}
        </Typography>
      </Breadcrumbs>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        <Typography variant="h5" sx={{ textAlign: 'left', mb: 2 }}>
          {t('writingRules.title')}
        </Typography>
        <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }}>
          <TextField
            label={t('writingRules.search.label')}
            variant="outlined"
            size="small"
            value={tempSearch}
            InputLabelProps={{
              shrink: true,
            }}
            placeholder={t('writingRules.search.placeholder')}
            onChange={(e) => {
              setTempSearch(e.target.value);
              setPage(0);
            }}
          />
          <Box>
            <LoadingButton
              ref={actionsButtonRef}
              color="info"
              onClick={handleMenuOpen}
              endIcon={<ArrowDropDownIcon />}
              variant="outlined"
              disabled={selectedId === null || menuLoading}
              loading={menuLoading}
            >
              {t('writingRules.actions.button')}
            </LoadingButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              slotProps={{
                paper: {
                  style: {
                    minWidth: actionsButtonRef.current?.offsetWidth,
                  },
                },
              }}
            >
              <MenuItem
                onClick={() => navigate(`/writing-rule/${selectedId}/edit`)}
                disabled={!selectedId || menuLoading}
              >
                <Typography>{t('writingRules.actions.edit')}</Typography>
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={handleDelete}
                disabled={!selectedId || menuLoading}
              >
                <Typography color="error">
                  {t('writingRules.actions.delete')}
                </Typography>
              </MenuItem>
            </Menu>
            <Button
              component={Link}
              to="/writing-rule/new"
              variant="contained"
              color="primary"
              sx={{ ml: 1 }}
            >
              {t('writingRules.actions.new')}
            </Button>
          </Box>
        </Box>
        <PaginatedTable
          singleSelection
          totalCount={data?.data.metadata.totalCount}
          columns={writingRuleColumns}
          data={data?.data.data ?? []}
          selectedItems={[selectedId]}
          page={page}
          rowsPerPage={rowsPerPage}
          loading={isLoadingData}
          getItemId={(item) => item.id}
          onItemClick={(uuid) => {
            setSelectedId(uuid);
          }}
          onPageChange={(_, newPage) => {
            setPage(newPage);
            setSelectedId(null);
          }}
          onRowsPerPageChange={(e) => {
            setRowsPerPage(parseInt(e.target.value, 10));
            setPage(0);
            setSelectedId(null);
          }}
        />
      </Paper>
    </Container>
  );
};
