/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ThinkingContentDto } from '@ink-ai/insight-service-sdk';

const initialState = {
  isDialogOpen: false,
  thinkingContent: '',
  taskId: '',
  isThinkingEnabled: false,
};

export type ThinkingState = typeof initialState;

// Create the thinking slice
export const thinking = createSlice({
  name: 'thinking',
  initialState,
  reducers: {
    // Toggle the dialog visibility
    toggleThinkingDialog: (state) => {
      state.isDialogOpen = !state.isDialogOpen;
    },

    // Explicitly open the dialog
    openThinkingDialog: (state) => {
      state.isDialogOpen = true;
    },

    // Explicitly close the dialog
    closeThinkingDialog: (state) => {
      state.isDialogOpen = false;
    },

    // Enable or disable thinking functionality
    setThinkingEnabled: (state, { payload }: PayloadAction<boolean>) => {
      state.isThinkingEnabled = payload;
      state.isDialogOpen = payload;
    },

    // Update the thinking content with new streamed data
    updateThinkingContent: (
      state,
      { payload }: PayloadAction<ThinkingContentDto>,
    ) => {
      state.isThinkingEnabled = true;
      state.isDialogOpen = true;
      // Verify the taskId matches if specified
      if (payload.id && state.taskId !== payload.id) {
        console.warn('TaskId mismatch, replace task ID');
        state.thinkingContent = '';
        state.taskId = payload.id;
      }
      state.isDialogOpen = true;
      state.thinkingContent += payload.payload;
    },
    clearThinking: () => {
      return initialState;
    },
    replaceThinkingContent: (state, { payload }: PayloadAction<string>) => {
      state.thinkingContent = payload;
    },
  },
});

// Export the action creators
export const {
  toggleThinkingDialog,
  openThinkingDialog,
  closeThinkingDialog,
  updateThinkingContent,
  clearThinking,
  replaceThinkingContent,
  setThinkingEnabled,
} = thinking.actions;

// Export the reducer
export default thinking.reducer;
