/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAsyncData } from '../../common/hooks/useAsyncData';
import { getApi } from '../../common/requestHelper';
import {
  ArticleApi,
  ArticleResponseItemDto,
} from '@ink-ai/insight-service-sdk';
import {
  Box,
  Breadcrumbs,
  Button,
  Chip,
  Container,
  Divider,
  Menu,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { EllipsisCell } from '@ink-ai/portal/common/components/paginated-table/EllipsisCell';
import { formatDate } from '@ink-ai/portal/common/utils';
import { PaginatedTable } from '@ink-ai/portal/common/components/paginated-table/PaginatedTable';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { downloadArticle } from '@ink-ai/portal/reducers/article';
import { debounce } from 'lodash-es';
import { LoadingButton } from '@mui/lab';

const useArticleColumns = () => {
  const { t } = useTranslation();
  return [
    {
      id: 'title',
      label: t('articles.table.title'),
      render: ({ title, id }: ArticleResponseItemDto) => (
        <EllipsisCell
          content={
            <Link
              className="cursor-pointer text-blue-600"
              to={`/articles/${id}/edit`}
            >
              {title}
            </Link>
          }
          tooltip={title}
        />
      ),
    },
    // {
    //   id: 'tags',
    //   label: 'Tags',
    //   render: ({ tags }: ArticleResponseItemDto) => (
    //     <Stack direction="row" spacing={1}>
    //       {tags.map((tag) => (
    //         <Chip key={tag} label={tag} color="primary" variant="outlined" />
    //       ))}
    //     </Stack>
    //   ),
    // },
    {
      id: 'createdAt',
      label: t('articles.table.createdAt'),
      render: ({ createdAt }: ArticleResponseItemDto) => formatDate(createdAt),
    },
    {
      id: 'updatedAt',
      label: t('articles.table.updatedAt'),
      render: ({ updatedAt }: ArticleResponseItemDto) => formatDate(updatedAt),
    },
  ];
};

export const ArticlesList = () => {
  const { t } = useTranslation();
  const articleColumns = useArticleColumns();
  const [page, setPage] = useState(0);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const actionsButtonRef = useRef<HTMLButtonElement>(null);
  const [search, setSearch] = useState('');
  const [tempSearch, setTempSearch] = useState('');
  const [menuLoading, setMenuLoading] = useState(false);
  const navigate = useNavigate();
  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const { data, isLoadingData, reloadData } = useAsyncData(async () => {
    const articleApi = await getApi(ArticleApi);
    return articleApi.queryArticle({
      pagination: {
        take: rowsPerPage,
        skip: page * rowsPerPage,
      },
      search,
    });
  }, [page, rowsPerPage, search]);
  const deleteArticle = async () => {
    if (!selectedId) {
      return;
    }
    const articleApi = await getApi(ArticleApi);
    await articleApi.deleteArticle(selectedId);
  };

  const handleDelete = async () => {
    setMenuLoading(true);
    try {
      await deleteArticle();
      reloadData();
      handleMenuClose();
      setSelectedId(null);
    } catch (error) {
      console.error(error);
    } finally {
      setMenuLoading(false);
    }
  };

  const handleExport = async () => {
    if (!selectedId) {
      return;
    }
    setMenuLoading(true);
    try {
      await downloadArticle(selectedId);
      handleMenuClose();
    } catch (error) {
      console.error;
      console.error();
    } finally {
      setMenuLoading(false);
    }
  };

  const debouncedSetSearch = useCallback(debounce(setSearch, 500), [setSearch]);

  useEffect(() => {
    debouncedSetSearch(tempSearch);
  }, [tempSearch, debouncedSetSearch]);

  return (
    <Container maxWidth="lg" sx={{ mt: 1, mb: 1 }}>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link to="/">{t('articles.navigation.home')}</Link>
        <Typography color="textPrimary">
          {t('articles.navigation.articles')}
        </Typography>
      </Breadcrumbs>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        <Typography variant="h5" sx={{ textAlign: 'left', mb: 2 }}>
          {t('articles.title')}
        </Typography>
        <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }}>
          <TextField
            label={t('articles.search.label')}
            variant="outlined"
            size="small"
            value={tempSearch}
            InputLabelProps={{
              shrink: true,
            }}
            placeholder={t('articles.search.placeholder')}
            onChange={(e) => {
              setTempSearch(e.target.value);
              setPage(0);
            }}
          />
          <Box>
            <LoadingButton
              ref={actionsButtonRef}
              color="info"
              onClick={handleMenuOpen}
              endIcon={<ArrowDropDownIcon />}
              variant="outlined"
              disabled={selectedId === null || menuLoading}
              loading={menuLoading}
            >
              {t('articles.actions.button')}
            </LoadingButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              slotProps={{
                paper: {
                  style: {
                    minWidth: actionsButtonRef.current?.offsetWidth,
                  },
                },
              }}
            >
              <MenuItem
                onClick={() => navigate(`/articles/${selectedId}/edit`)}
                disabled={!selectedId || menuLoading}
              >
                <Typography>{t('articles.actions.edit')}</Typography>
              </MenuItem>
              <MenuItem
                onClick={handleExport}
                disabled={!selectedId || menuLoading}
              >
                <Typography>{t('articles.actions.export')}</Typography>
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={handleDelete}
                disabled={!selectedId || menuLoading}
              >
                <Typography color="error">
                  {t('articles.actions.delete')}
                </Typography>
              </MenuItem>
            </Menu>
            <Button
              component={Link}
              to="/articles/new"
              variant="contained"
              color="primary"
              sx={{ ml: 1 }}
            >
              {t('articles.actions.new')}
            </Button>
          </Box>
        </Box>
        <PaginatedTable
          singleSelection
          totalCount={data?.data.metadata.totalCount}
          columns={articleColumns}
          data={data?.data.data ?? []}
          selectedItems={[selectedId]}
          page={page}
          rowsPerPage={rowsPerPage}
          loading={isLoadingData}
          getItemId={(item) => item.id}
          onItemClick={(uuid) => {
            setSelectedId(uuid);
          }}
          onPageChange={(_, newPage) => {
            setPage(newPage);
            setSelectedId(null);
          }}
          onRowsPerPageChange={(e) => {
            setRowsPerPage(parseInt(e.target.value, 10));
            setPage(0);
            setSelectedId(null);
          }}
        />
      </Paper>
    </Container>
  );
};
