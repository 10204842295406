/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Container,
  Paper,
  TextField,
  Typography,
  MenuItem,
  Menu,
  Breadcrumbs,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { connectorActions, deleteConnector } from '../../../reducers/connector';
import { useNavigate } from 'react-router-dom';
import { useNavigationListener } from '../../components/hooks/useNavigationListener';
import { ConnectorTable } from '../../components/connector/ConnectorTable';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export const ConnectorsSettings: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();
  const actionsButtonRef = useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedConnectors, setSelectedConnectors] = useState<string[]>([]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    dispatch(connectorActions.setLoading(true));
    handleMenuClose();
    dispatch(deleteConnector(selectedConnectors));
    setSelectedConnectors([]);
  };

  useNavigationListener();

  return (
    <Container maxWidth="lg" sx={{ mt: 1, mb: 1 }}>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Typography color="textPrimary">
          {t('connectors.navigation.settings')}
        </Typography>
        <Typography color="textPrimary">
          {t('connectors.navigation.connectors')}
        </Typography>
      </Breadcrumbs>
      <Paper sx={{ p: 2 }}>
        <Typography variant="h5" sx={{ textAlign: 'left', mb: 2 }}>
          {t('connectors.title')}
        </Typography>
        <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }}>
          <TextField
            label={t('connectors.search.label')}
            variant="outlined"
            size="small"
            value={searchTerm}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleSearchChange}
          />
          <Box>
            <Button
              ref={actionsButtonRef}
              color="primary"
              onClick={handleMenuOpen}
              endIcon={<ArrowDropDownIcon />}
              variant="outlined"
              sx={{
                borderColor: 'black',
                color: 'black',
                '&:hover': {
                  borderColor: 'black',
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
              }}
              disabled={selectedConnectors.length === 0}
            >
              {t('connectors.actions.button')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{ ml: 1 }}
              onClick={() => navigate('/settings/connector/import')}
            >
              {t('connectors.actions.import')}
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              slotProps={{
                paper: {
                  style: {
                    minWidth: actionsButtonRef.current?.offsetWidth,
                  },
                },
              }}
            >
              <MenuItem
                onClick={handleDelete}
                disabled={selectedConnectors.length === 0}
              >
                {t('connectors.actions.delete')}
              </MenuItem>
            </Menu>
          </Box>
        </Box>
        <ConnectorTable
          selectedConnectors={selectedConnectors}
          onSelectedConnectorsChange={setSelectedConnectors}
          searchTerm={searchTerm}
          page={page}
          onPageChange={setPage}
        />
      </Paper>
    </Container>
  );
};
