/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React, { useEffect } from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import { alpha, useTheme } from '@mui/material/styles';
import { bgGradient } from '../../theme/css';
import Logo from '../components/Logo';
import { useDispatch, useSelector } from 'react-redux';
import { auth, grantToken, login } from '@ink-ai/portal/reducers/auth';
import { RootState, getStore } from '@ink-ai/portal/reducers';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const receiveCode = (event: MessageEvent) => {
  if (event.origin !== window.location.origin) {
    return;
  }
  const data = event.data;
  if (data?.source !== 'ink-ai-oidc') {
    return;
  }
  const store = getStore();
  console.log('Received secret key:', data.code);
  store.dispatch(
    grantToken({
      grant_type: 'authorization_code',
      code: data.code,
    }),
  );
};

window.addEventListener('message', receiveCode, false);

export const Login = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch<any>();
  const authState = useSelector((state: RootState) => state.auth);
  const appState = useSelector((state: RootState) => state.app);
  const location = useLocation();
  const navigate = useNavigate();

  const onLoginClick = () => {
    dispatch(auth.actions.changeLoading(true));
    const oidcPage = `${appState.appParam.authorization_endpoint}?scope=openid profile email&response_type=code&client_id=${appState.appParam.cognito_client_id}&redirect_uri=${appState.addInHost}/oidc.html`;
    console.log('Open cognito page: ', oidcPage);
    const loginPopup = window.open(oidcPage, 'Login', 'width=600,height=400');
    const checkPopupClosed = setInterval(() => {
      if (loginPopup.closed) {
        clearInterval(checkPopupClosed);
        if (!getStore().getState().auth.grantTokenLoading) {
          dispatch(auth.actions.changeLoading(false));
        }
      }
    }, 1000);
  };

  useEffect(() => {
    if (!authState.accessToken) {
      return;
    }
    if (
      authState.expiresAt &&
      authState.expiresAt * 1000 <= Date.now() &&
      authState.refreshToken
    ) {
      console.log('access token expired, refreshing token');
      dispatch(
        grantToken({
          grant_type: 'refresh_token',
          refresh_token: authState.refreshToken,
        }),
      );
      return;
    }
    dispatch(login());
  }, [authState.accessToken]);

  useEffect(() => {
    if (authState.loggedIn) {
      const from = location.state?.from?.pathname || '/';
      navigate(from, { replace: true });
    }
  }, [authState.loggedIn]);

  const renderForm = (
    <>
      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        color="inherit"
        onClick={onLoginClick}
        loading={authState.loading}
      >
        {t('common.login')}
      </LoadingButton>
    </>
  );

  return (
    <Box
      sx={{
        ...bgGradient({
          color: alpha(theme.palette.background.default, 0.9),
          imgUrl: '/images/overlay_4.jpg',
        }),
        height: '100vh',
      }}
    >
      <Logo
        sx={{
          position: 'fixed',
          top: { xs: 16, md: 24 },
          left: { xs: 16, md: 24 },
        }}
      />

      <Stack alignItems="center" justifyContent="center" sx={{ height: 1 }}>
        <Card
          sx={{
            p: 5,
            width: 1,
            maxWidth: 420,
          }}
        >
          <Typography variant="h4" sx={{ marginBottom: '20px' }}>
            {t('solutionName')}
          </Typography>
          {renderForm}
        </Card>
      </Stack>
    </Box>
  );
};
