/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Typography,
  Link,
  LinearProgress,
  IconButton,
} from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import TranslateIcon from '@mui/icons-material/Translate';
import { LoadingButton } from '@mui/lab';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  handleDownload,
  refreshTranslationStatus,
  TranslationState,
} from '@ink-ai/portal/reducers/translation';
import { useDispatch } from 'react-redux';

interface FileUploadAreaProps {
  translation: TranslationState;
  handleDrop: (event: React.DragEvent<HTMLDivElement>) => void;
  handleDragOver: (event: React.DragEvent<HTMLDivElement>) => void;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleTranslate: () => void;
  handleRemoveFile: () => void;
  formatFileSize: (size: number) => string;
}

export const DocumentTranslateArea: React.FC<FileUploadAreaProps> = ({
  translation,
  handleDrop,
  handleDragOver,
  handleFileChange,
  handleTranslate,
  handleRemoveFile,
  formatFileSize,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();
  return (
    <Box
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        border: translation.fileName ? 'none' : '2px dashed #ccc',
        borderRadius: '4px',
        padding: '20px',
        textAlign: 'center',
        width: '650px',
        height: '200px',
        margin: '2rem auto',
      }}
    >
      <input
        accept=".docx, .pdf"
        className="hidden"
        id="upload-file"
        type="file"
        onChange={handleFileChange}
      />
      {!translation.fileName ? (
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
            <UploadFileIcon sx={{ fontSize: '24px', color: '#2196F3' }} />
          </Box>
          <label htmlFor="upload-file" style={{ cursor: 'pointer' }}>
            <Link component="span" underline="hover">
              {t('translation.document.clickToUpload')}
            </Link>
          </label>
          &nbsp;{t('translation.document.dragAndDrop')}
          <Typography variant="body2" color="text.secondary">
            {t('translation.document.fileFormat')}
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: 1,
            backgroundColor: '#f5f5f5',
            padding: '10px',
            borderRadius: '4px',
            width: '100%',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <InsertDriveFileIcon sx={{ mr: 3, color: '#2196F3' }} />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                flexGrow: 1,
              }}
            >
              <Typography variant="body2">{translation.fileName}</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body2" color="text.secondary">
                  {formatFileSize(translation.fileSize)}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ ml: 1 }}
                >
                  {translation.translationStatus.charAt(0).toUpperCase() +
                    translation.translationStatus.slice(1)}
                </Typography>
              </Box>
            </Box>
            {translation.translationStatus === 'translating' && (
              <IconButton
                onClick={() => dispatch(refreshTranslationStatus())}
                sx={{ ml: 2 }}
              >
                <RefreshIcon />
              </IconButton>
            )}
            <IconButton onClick={handleRemoveFile} sx={{ ml: 2 }}>
              <DeleteIcon />
            </IconButton>
          </Box>
          {translation.translationStatus === 'uploading' && (
            <Box sx={{ width: '80%', mt: 2 }}>
              <LinearProgress
                variant="determinate"
                value={translation.progress}
              />
            </Box>
          )}
        </Box>
      )}
      {translation.fileName && (
        <LoadingButton
          variant="contained"
          loadingPosition="start"
          color="primary"
          sx={{ alignSelf: 'flex-end', mt: 2, textTransform: 'none' }}
          onClick={
            translation.translationStatus === 'completed'
              ? () => handleDownload(translation.uuid)
              : handleTranslate
          }
          loading={translation.loading}
          startIcon={
            translation.translationStatus === 'completed' ? (
              <DownloadIcon />
            ) : translation.translationStatus ===
              'waiting upload' ? undefined : (
              <TranslateIcon /> // this icon is useless, just for placeholder
            )
          }
        >
          {translation.translationStatus === 'completed'
            ? t('translation.document.download')
            : translation.translationStatus === 'waiting upload'
            ? t('translation.document.translate')
            : t('translation.document.translating')}
        </LoadingButton>
      )}
    </Box>
  );
};
