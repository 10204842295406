/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React, {
  useState,
  useCallback,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import axios, { AxiosProgressEvent, CanceledError } from 'axios';
import { Box, Typography, Link, LinearProgress } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  DocumentUploadApi,
  DocumentUploadStatus,
  StiType,
} from '@ink-ai/insight-service-sdk';
import { formatFileSize } from '@ink-ai/portal/common/utils';
import { getApi } from '@ink-ai/portal/common/requestHelper';

export enum FileUploadStatus {
  WaitingUpload = 'fileUpload.status.waitingUpload',
  Complete = 'fileUpload.status.complete',
  Error = 'fileUpload.status.error',
  Uploading = 'fileUpload.status.uploading',
  Importing = 'fileUpload.status.importing',
  Selected = 'fileUpload.status.selected',
}

interface UploadMetadata {
  fileName: string;
  fileSize: number;
  fileType: string;
}

interface UploadState {
  status: FileUploadStatus;
  progress: number;
  error?: string;
  uploadUrl?: string;
  uploadTaskId?: string;
  metadata?: UploadMetadata;
  file?: File;
}

interface FileUploadProps {
  accept?: string;
  hintText?: string;
  disabled?: boolean;
  containerSx?: Record<string, any>;
  ownerType: StiType;
  onUploadError?: (error: Error) => void;
  onUploadComplete?: (uploadTaskId: string) => void;
  onFileSelect?: (file: File) => void;
  onFileRemove?: () => void;
  onStatusChange?: (status: FileUploadStatus) => void;
}

export interface FileUploadRef {
  startFileUpload: () => Promise<void>;
}

export const FileUpload = forwardRef<FileUploadRef, FileUploadProps>(
  (
    {
      accept = '.json, .csv',
      hintText,
      disabled = false,
      containerSx = {},
      ownerType,
      onUploadError,
      onUploadComplete,
      onFileSelect,
      onFileRemove,
      onStatusChange,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const abortController = useRef<AbortController>();
    const createSafeCallback = useCallback(
      <T extends (...args: any[]) => any>(callback?: T) => {
        return (...args: Parameters<T>) => {
          if (!abortController.current?.signal.aborted && callback) {
            return callback(...args);
          }
        };
      },
      [],
    );

    useEffect(() => {
      return () => {
        // Abort any ongoing requests when the component is unmounted
        abortController.current?.abort();
      };
    }, []);

    const initialState: UploadState = {
      status: FileUploadStatus.WaitingUpload,
      progress: 0,
    };

    const [uploadState, setUploadState] = useState<UploadState>(initialState);

    const handleReset = useCallback(() => {
      console.log('handleReset called');
      abortController.current?.abort();
      abortController.current = new AbortController();

      setUploadState(initialState);
      onFileRemove?.();
    }, [onFileRemove]);

    const startFileUpload = useCallback(async () => {
      if (!uploadState.file) {
        console.warn('No file selected');
        return;
      }
      abortController.current = new AbortController();
      const { signal } = abortController.current;
      const documentUploadApi = await getApi(DocumentUploadApi);

      try {
        setUploadState((prev) => ({
          ...prev,
          status: FileUploadStatus.Uploading,
          progress: 0,
        }));
        createSafeCallback(onStatusChange)?.(FileUploadStatus.Uploading);
        const response = await documentUploadApi.createUploadTask({
          ownerType,
        });

        const { uploadUrl, uploadTaskId } = response.data;

        setUploadState((prev) => ({
          ...prev,
          uploadUrl,
          uploadTaskId,
        }));

        await axios.put(uploadUrl, uploadState.file, {
          signal,
          headers: {
            'Content-Type': uploadState.file.type || 'application/octet-stream',
          },
          onUploadProgress: (progressEvent: AxiosProgressEvent) => {
            if (!signal.aborted) {
              const progress = Math.round(
                (progressEvent.loaded * 100) / (progressEvent.total || 1),
              );
              setUploadState((prev) => ({
                ...prev,
                progress,
                status: FileUploadStatus.Uploading,
              }));
            }
          },
        });

        setUploadState((prev) => ({
          ...prev,
          status: FileUploadStatus.Importing,
        }));
        await documentUploadApi.updateUploadTaskStatus(uploadTaskId, {
          status: DocumentUploadStatus.UploadSuccess,
        });

        if (uploadTaskId) {
          createSafeCallback(onUploadComplete)?.(uploadTaskId);
        }
      } catch (error: any) {
        console.error('Error during file upload:', error);
        const isCancelError =
          error instanceof CanceledError ||
          error.name === 'CanceledError' ||
          error.message?.toLowerCase().includes('cancel');

        if (!isCancelError) {
          setUploadState((prev) => ({
            ...prev,
            status: FileUploadStatus.Error,
            error: error.message || t('fileUpload.errorMessage'),
          }));
          createSafeCallback(onUploadError)?.(error);
          if (uploadState.uploadTaskId) {
            await documentUploadApi.updateUploadTaskStatus(
              uploadState.uploadTaskId,
              {
                status: DocumentUploadStatus.UploadError,
                errorMessage: error.message || t('fileUpload.errorMessage'),
              },
            );
          }
          throw error;
        } else {
          // Reset the upload state if the upload was cancelled
          setUploadState(initialState);
        }
      }
    }, [uploadState.file, ownerType, onUploadComplete, onUploadError]);

    useImperativeHandle(ref, () => ({
      startFileUpload,
    }));

    useEffect(() => {
      onStatusChange?.(uploadState.status);
    }, [uploadState.status, onStatusChange]);

    const handleFileSelection = useCallback(
      async (file: File) => {
        onFileSelect?.(file);

        setUploadState((prev) => ({
          ...prev,
          file,
          status: FileUploadStatus.Selected,
          metadata: {
            fileName: file.name,
            fileSize: file.size,
            fileType: file.type,
          },
        }));
      },
      [onFileSelect, startFileUpload],
    );

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      if (disabled) return;

      const file = event.dataTransfer.files[0];
      if (file) {
        handleFileSelection(file);
      }
    };

    const showUploadBox = uploadState.status === FileUploadStatus.WaitingUpload;
    const isUploading = uploadState.status === FileUploadStatus.Uploading;

    return (
      <Box
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        sx={{
          border: '1px dashed grey',
          p: 2,
          mb: 2,
          textAlign: 'center',
          borderRadius: '4px',
          borderColor: uploadState.error ? 'error.main' : 'grey.400',
          opacity: disabled ? 0.5 : 1,
          ...containerSx,
        }}
      >
        <input
          accept={accept}
          className="hidden"
          id="upload-file"
          type="file"
          onChange={(e) => {
            const file = e.target.files?.[0];
            if (file) {
              handleFileSelection(file);
            }
          }}
          style={{ display: 'none' }}
          disabled={disabled}
        />
        {showUploadBox ? (
          <Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mb: 2,
              }}
            >
              <UploadFileIcon
                sx={{
                  fontSize: '24px',
                  color: '#2196F3',
                }}
              />
            </Box>
            <label htmlFor="upload-file" className="cursor-pointer">
              <Link
                component="span"
                underline="hover"
                sx={{ pointerEvents: disabled ? 'none' : 'auto' }}
              >
                {t('fileUpload.clickToUpload')}
              </Link>
            </label>
            &nbsp;{t('fileUpload.dragAndDrop')}
            <Typography variant="body2" color="text.secondary">
              {accept}
            </Typography>
            {hintText && (
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                {hintText}
              </Typography>
            )}
          </Box>
        ) : (
          <Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                mb: 1,
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                <InsertDriveFileIcon
                  sx={{
                    mr: 3,
                    color: uploadState.error ? 'error.main' : '#2196F3',
                  }}
                />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}
                >
                  <Typography variant="body2">
                    {uploadState.metadata?.fileName}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {uploadState.metadata?.fileSize &&
                      formatFileSize(uploadState.metadata.fileSize)}{' '}
                    • {t(uploadState.status)}
                  </Typography>
                  {uploadState.error && (
                    <Typography variant="body2" color="error">
                      {uploadState.error}
                    </Typography>
                  )}
                </Box>
              </Box>
              {!disabled && (
                <DeleteIcon
                  sx={{ cursor: 'pointer', color: '#0000008F', ml: 2 }}
                  onClick={handleReset}
                />
              )}
            </Box>
            {isUploading && (
              <Box sx={{ width: '100%' }}>
                <LinearProgress
                  variant="determinate"
                  value={uploadState.progress}
                />
              </Box>
            )}
          </Box>
        )}
      </Box>
    );
  },
);
FileUpload.displayName = 'FileUpload';
