/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { Grid } from '@mui/system';
import {
  ModelConfigDtoFunctionModuleEnum,
  ModelConfigDtoModelEnum,
} from '@ink-ai/insight-service-sdk';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@ink-ai/portal/reducers';
import { configuration } from '@ink-ai/portal/reducers/configuration';

export const models = {
  auto: 'Auto',
  [ModelConfigDtoModelEnum.AmazonNovaLite]: 'Amazon Nova Lite',
  [ModelConfigDtoModelEnum.AmazonNovaPro]: 'Amazon Nova Pro',
  [ModelConfigDtoModelEnum.Claude3Haiku]: 'Anthropic Claude 3 Haiku',
  [ModelConfigDtoModelEnum.Claude3Sonnet]: 'Anthropic Claude 3 Sonnet',
  [ModelConfigDtoModelEnum.Claude3Opus]: 'Anthropic Claude 3 Opus',
  [ModelConfigDtoModelEnum.Claude35Haiku]: 'Anthropic Claude 3.5 Haiku',
  [ModelConfigDtoModelEnum.Claude35Sonnet]: 'Anthropic Claude 3.5 Sonnet',
  [ModelConfigDtoModelEnum.Claude35SonnetV2]: 'Anthropic Claude 3.5 Sonnet V2',
  [ModelConfigDtoModelEnum.Claude37Sonnet]: 'Anthropic Claude 3.7 Sonnet',
  [ModelConfigDtoModelEnum.Claude37SonnetReasoning]:
    'Anthropic Claude 3.7 Sonnet with reasoning',
  [ModelConfigDtoModelEnum.DeepseekR1]: 'DeepSeek R1',
};

interface ModelConfigForFunctionModuleProps {
  functionModule: ModelConfigDtoFunctionModuleEnum;
}

export const ModelConfigForFunctionModule = ({
  functionModule,
}: ModelConfigForFunctionModuleProps) => {
  const { t } = useTranslation();
  const modelConfig = useSelector(
    (state: RootState) => state.configuration.modelConfig ?? [],
  );
  const dispatch = useDispatch();
  const model =
    modelConfig.find(
      (modelConfig) => modelConfig.functionModule === functionModule,
    )?.model ?? 'auto';
  const { name, description } = {
    name: t(`modelConfig.functionModules.${functionModule}.name`),
    description: t(`modelConfig.functionModules.${functionModule}.description`),
  };
  return (
    <>
      <Grid size={3}>
        <Typography
          variant="body1"
          sx={{
            mt: '8px',
            fontWeight: 700,
            fontSize: '16px',
            color: '#000',
          }}
        >
          {name}
        </Typography>
      </Grid>
      <Grid size={6}>
        <FormControl fullWidth>
          <InputLabel id={`model-${functionModule}-label`}>
            {t('modelConfig.label')}
          </InputLabel>
          <Select
            labelId={`model-${functionModule}-label`}
            id={`model-${functionModule}`}
            label={t('modelConfig.label')}
            size="small"
            value={model}
            onChange={(e) =>
              dispatch(
                configuration.actions.updateModelConfig({
                  functionModule,
                  model: e.target.value as any,
                }),
              )
            }
          >
            {Object.entries(models).map(([modelId, modelName]) => (
              <MenuItem key={modelId} value={modelId}>
                {modelName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography>{description}</Typography>
      </Grid>
      <Grid size="grow"></Grid>
    </>
  );
};

export const ModelConfig = () => {
  const { t } = useTranslation();
  return (
    <Box position="relative">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Typography
          variant="body1"
          sx={{
            flexGrow: 1,
            marginRight: '40px',
            fontSize: '12px',
          }}
        >
          {t('modelConfig.description')}
        </Typography>
      </Box>
      <Grid container alignItems="flex-start" spacing={1} rowSpacing={5}>
        {Object.values(ModelConfigDtoFunctionModuleEnum).map((module) => (
          <ModelConfigForFunctionModule functionModule={module} key={module} />
        ))}
      </Grid>
    </Box>
  );
};
