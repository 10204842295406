/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import { PaginatedTable } from '@ink-ai/portal/common/components/paginated-table/PaginatedTable';
import { useAsyncData } from '@ink-ai/portal/common/hooks/useAsyncData';
import { getApi } from '@ink-ai/portal/common/requestHelper';
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Divider,
  Menu,
  MenuItem,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  DocumentTranslationApi,
  DocumentTranslationResponseItemDtoStatusEnum,
} from '@ink-ai/insight-service-sdk';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { EllipsisCell } from '@ink-ai/portal/common/components/paginated-table/EllipsisCell';
import { formatDate } from '@ink-ai/portal/common/utils';
import {
  handleDownload,
  languageOptions,
  statusClassMapping,
  statusStringMapping,
} from '@ink-ai/portal/reducers/translation';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import RefreshIcon from '@mui/icons-material/Refresh';
import { debounce } from 'lodash-es';
import { useSelector } from 'react-redux';
import { RootState } from '@ink-ai/portal/reducers';
import { WsMessageType } from '@ink-ai/portal/common/socket/SocketHandler';
import LoadingButton from '@mui/lab/LoadingButton';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import DownloadIcon from '@mui/icons-material/Download';

export const statusIconMapping: Record<
  DocumentTranslationResponseItemDtoStatusEnum,
  React.ReactNode
> = {
  [DocumentTranslationResponseItemDtoStatusEnum.PendingUpload]: (
    <PendingOutlinedIcon />
  ),
  [DocumentTranslationResponseItemDtoStatusEnum.Translating]: (
    <PendingOutlinedIcon />
  ),
  [DocumentTranslationResponseItemDtoStatusEnum.Success]: (
    <CheckCircleOutlineOutlinedIcon />
  ),
  [DocumentTranslationResponseItemDtoStatusEnum.Error]: (
    <ErrorOutlineOutlinedIcon />
  ),
};

export const TranslateDocumentTaskList = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [downloadId, setDownloadId] = useState('');
  const [menuLoading, setMenuLoading] = useState(false);
  const [lastSyncAt, setLastSyncAt] = useState(0);
  const [tempSearch, setTempSearch] = useState('');
  const [search, setSearch] = useState('');
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const actionsButtonRef = useRef<HTMLButtonElement>(null);
  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const webSocketState = useSelector((state: RootState) => state.webSocket);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const { data, isLoadingData, reloadData } = useAsyncData(async () => {
    const translationApi = await getApi(DocumentTranslationApi);
    return translationApi.queryDocumentTranslation({
      pagination: {
        take: rowsPerPage,
        skip: page * rowsPerPage,
      },
      search,
    });
  }, [page, rowsPerPage, search]);

  useEffect(() => {
    if (lastSyncAt === webSocketState.lastMessageAt) {
      // already in syncing
      return;
    }
    setLastSyncAt(webSocketState.lastMessageAt);
    if (isLoadingData) {
      return;
    }
    if (
      webSocketState.lastMessage?.type !==
      WsMessageType.DOCUMENT_TRANSLATION_RESULT
    ) {
      return;
    }
    const updatedId = webSocketState.lastMessage?.payload.uuid;

    // reload data if current data contains task that to be updated;
    if (
      data.data.data.some(
        ({ id, status }) => id === updatedId && status === 'TRANSLATING',
      )
    ) {
      reloadData();
    }
  }, [
    webSocketState.lastMessage,
    webSocketState.lastMessageAt,
    data,
    reloadData,
    isLoadingData,
    lastSyncAt,
  ]);

  const deleteTask = async () => {
    if (!selectedId) {
      return;
    }
    const translationApi = await getApi(DocumentTranslationApi);
    await translationApi.deleteDocumentTranslation(selectedId);
  };

  const handleDelete = async () => {
    setMenuLoading(true);
    try {
      await deleteTask();
    } catch (error) {
      console.error(error);
    } finally {
      setMenuLoading(false);
    }
    handleMenuClose();
    reloadData();
    setSelectedId(null);
  };

  const handleTaskMenuDownload = async () => {
    setMenuLoading(true);
    try {
      await handleDownload(selectedId);
    } catch (error) {
      console.error(error);
    } finally {
      setMenuLoading(false);
    }
    handleMenuClose();
  };

  const handleTaskDownload = async (id: string) => {
    setDownloadId(id);
    try {
      await handleDownload(id);
    } catch (error) {
      console.error(error);
    } finally {
      setDownloadId('');
    }
  };

  const debouncedSetSearch = useCallback(debounce(setSearch, 500), [setSearch]);

  useEffect(() => {
    debouncedSetSearch(tempSearch);
  }, [tempSearch, debouncedSetSearch]);

  const selectedTask = useMemo(() => {
    if (!selectedId) {
      return null;
    }
    return data?.data.data.find((item) => item.id === selectedId) ?? null;
  }, [selectedId, data]);

  return (
    <Container maxWidth="lg" sx={{ mt: 1, mb: 1 }}>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link to="/">{t('translateTasks.navigation.home')}</Link>
        <Typography color="textPrimary">
          {t('translateTasks.navigation.translateTasks')}
        </Typography>
      </Breadcrumbs>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }}>
          <TextField
            label={t('translateTasks.search.label')}
            variant="outlined"
            size="small"
            value={tempSearch}
            InputLabelProps={{
              shrink: true,
            }}
            placeholder={t('translateTasks.search.placeholder')}
            onChange={(e) => {
              setTempSearch(e.target.value);
              setPage(0);
            }}
          />
          <Box>
            <Button
              sx={{ mr: 1 }}
              aria-label="refresh"
              variant="outlined"
              onClick={reloadData}
            >
              <RefreshIcon />
            </Button>
            <LoadingButton
              ref={actionsButtonRef}
              color="info"
              onClick={handleMenuOpen}
              endIcon={<ArrowDropDownIcon />}
              variant="outlined"
              disabled={selectedId === null || menuLoading}
              loading={menuLoading}
            >
              {t('translateTasks.actions.button')}
            </LoadingButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              slotProps={{
                paper: {
                  style: {
                    minWidth: actionsButtonRef.current?.offsetWidth,
                  },
                },
              }}
            >
              <MenuItem
                onClick={handleTaskMenuDownload}
                disabled={
                  !selectedTask ||
                  selectedTask.status !==
                    DocumentTranslationResponseItemDtoStatusEnum.Success ||
                  menuLoading
                }
              >
                <Typography>{t('translateTasks.actions.download')}</Typography>
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={handleDelete}
                disabled={!selectedId || menuLoading}
              >
                <Typography color="error">
                  {t('translateTasks.actions.delete')}
                </Typography>
              </MenuItem>
            </Menu>
            <Button
              component={Link}
              to="/medical-translator"
              variant="contained"
              color="primary"
              sx={{ ml: 1 }}
            >
              {t('translateTasks.actions.new')}
            </Button>
          </Box>
        </Box>
        <PaginatedTable
          singleSelection
          totalCount={data?.data.metadata.totalCount}
          columns={[
            {
              id: 'name',
              label: t('translateTasks.table.filename'),
              render: ({ name }) => <EllipsisCell content={name} />,
              width: 200,
            },
            {
              id: 'source',
              label: t('translateTasks.table.sourceLanguage'),
              render: ({ sourceLang }) => t(languageOptions.get(sourceLang)),
            },
            {
              id: 'target',
              label: t('translateTasks.table.targetLanguage'),
              render: ({ targetLang }) => t(languageOptions.get(targetLang)),
            },
            {
              id: 'createdAt',
              label: t('translateTasks.table.createdAt'),
              render: ({ createdAt }) => formatDate(createdAt),
            },
            {
              id: 'updatedAt',
              label: t('translateTasks.table.updatedAt'),
              render: ({ updatedAt }) => formatDate(updatedAt),
            },
            {
              id: 'status',
              label: t('translateTasks.table.status'),
              render: ({ status, errorMessage, id }) =>
                status === 'SUCCESS' ? (
                  <LoadingButton
                    color="info"
                    size="small"
                    onClick={() => handleTaskDownload(id)}
                    startIcon={<DownloadIcon />}
                    variant="outlined"
                    disabled={id === downloadId}
                    loading={id === downloadId}
                  >
                    {t('translateTasks.actions.download')}
                  </LoadingButton>
                ) : (
                  <Tooltip title={errorMessage}>
                    <Typography
                      variant="body2"
                      className={statusClassMapping[status]}
                    >
                      {statusIconMapping[status]}
                      <span className="align-middle ml-1">
                        {t(statusStringMapping[status])}
                      </span>
                    </Typography>
                  </Tooltip>
                ),
            },
          ]}
          data={data?.data.data ?? []}
          selectedItems={[selectedId]}
          page={page}
          rowsPerPage={rowsPerPage}
          loading={isLoadingData}
          getItemId={(item) => item.id}
          onItemClick={(uuid) => {
            setSelectedId(uuid);
          }}
          onPageChange={(_, newPage) => {
            setPage(newPage);
            setSelectedId(null);
          }}
          onRowsPerPageChange={(e) => {
            setRowsPerPage(parseInt(e.target.value, 10));
            setPage(0);
            setSelectedId(null);
          }}
        />
      </Paper>
    </Container>
  );
};
