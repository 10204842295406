/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Container,
  Paper,
  IconButton,
  Button,
  Badge,
  Breadcrumbs,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import DescriptionIcon from '@mui/icons-material/Description';
import TranslateIcon from '@mui/icons-material/Translate';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { GlossaryDialog } from '../components/glossary/GlossaryCollectionsDialog';
import { getStore, RootState } from '@ink-ai/portal/reducers';
import {
  translationActions,
  uploadAndTranslate,
} from '@ink-ai/portal/reducers/translation';
import { app } from '@ink-ai/portal/reducers/app';
import { LanguageTabs } from '../components/translation/LanguageTabs';
import { DocumentTranslateArea } from '../components/translation/DocumentTranslateArea';
import { TextTranslateArea } from '../components/translation/TextTranslateArea';
import { DocumentTranslationOptionDtoTargetLanguageEnum } from 'packages/insight-service-sdk/dist';
import { compose } from '@ink-ai/portal/reducers/compose';
import { Link, useBlocker } from 'react-router-dom';
import { ReferenceStoreDialog } from '../components/reference-store/ReferenceStoreDialog';

const confirmExit = () => {
  const translation = getStore().getState().translation;
  if (translation.translationStatus === 'uploading') {
    return 'The document is still in the process of uploading. Are you sure, you want to close?';
  }
};

export const Translation: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();
  const store = getStore();
  const composeState = useSelector((state: RootState) => state.compose);
  const translation = useSelector((state: RootState) => state.translation);
  const [anchorElSource, setAnchorElSource] = useState<null | HTMLElement>(
    null,
  );
  const [anchorElTarget, setAnchorElTarget] = useState<null | HTMLElement>(
    null,
  );

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      translation.translationStatus === 'uploading' &&
      currentLocation.pathname !== nextLocation.pathname,
  );

  useEffect(() => {
    window.onbeforeunload = confirmExit;
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  useEffect(() => {
    // Clean up on unmount
    return () => {
      dispatch(translationActions.clearAll());
      dispatch(compose.actions.clearAll());
    };
  }, []);

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    handleFileChange({
      target: { files },
    } as React.ChangeEvent<HTMLInputElement>);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    const allowedTypes = [
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/pdf',
    ];

    if (file && allowedTypes.includes(file.type)) {
      dispatch(translationActions.setSelectedFile(file));
      dispatch(translationActions.setFileName(file.name));
      dispatch(translationActions.setFileSize(file.size));
    } else {
      dispatch(translationActions.setSelectedFile(null));
      store.dispatch(
        app.actions.setGlobalMessage({
          message: 'Invalid file type. Please upload a .docx or .pdf file.',
          status: 'error',
        }),
      );
    }
  };

  const handleSwapLanguages = () => {
    const newSource = translation.targetLanguage;
    const newTarget = translation.sourceLanguage;
    dispatch(translationActions.setSourceLanguage(newSource));
    dispatch(translationActions.setTargetLanguage(newTarget));
    if (translation.mode === 'Text' && composeState.generatedText) {
      dispatch(translationActions.setSourceText(composeState.generatedText));
      dispatch(compose.actions.clearAll());
    }
  };

  const handleGlossaryButtonClick = () => {
    dispatch(translationActions.setGlossaryDialogOpen(true));
  };

  const handleGlossaryDialogClose = () => {
    dispatch(translationActions.setGlossaryDialogOpen(false));
  };

  const handleGlossaryApply = (selectedGlossaryUuids: string[]) => {
    dispatch(translationActions.setSelectedGlossaries(selectedGlossaryUuids));
    dispatch(translationActions.setGlossaryDialogOpen(false));
  };

  const handleReferenceStoreButtonClick = () => {
    dispatch(translationActions.setReferenceStoreDialogOpen(true));
  };

  const handleReferenceStoreDialogClose = () => {
    dispatch(translationActions.setReferenceStoreDialogOpen(false));
  };

  const handleReferenceStoreApply = (selectedReferenceUuids: string[]) => {
    dispatch(
      translationActions.setSelectedReferenceStores(selectedReferenceUuids),
    );
    dispatch(translationActions.setReferenceStoreDialogOpen(false));
  };

  const handleTranslate = async () => {
    try {
      dispatch(translationActions.setLoading(true));
      if (translation.selectedFile) {
        dispatch(uploadAndTranslate());
      } else {
        console.error('No file selected.');
      }
    } catch (error) {
      console.error(error);
      dispatch(translationActions.setLoading(false));
    }
  };

  const formatFileSize = (size: number) => {
    if (size < 1024) return `${size} bytes`;
    if (size < 1024 * 1024) return `${(size / 1024).toFixed(2)} kb`;
    if (size < 1024 * 1024 * 1024)
      return `${(size / (1024 * 1024)).toFixed(2)} mb`;
    return `${(size / (1024 * 1024 * 1024)).toFixed(2)} gb`;
  };

  const handleSourceLanguageChange = (
    language: DocumentTranslationOptionDtoTargetLanguageEnum,
  ) => {
    if (language === translation.targetLanguage) {
      handleSwapLanguages();
    } else {
      dispatch(translationActions.setSourceLanguage(language));
    }
  };

  const handleTargetLanguageChange = (
    language: DocumentTranslationOptionDtoTargetLanguageEnum,
  ) => {
    if (language === translation.sourceLanguage) {
      handleSwapLanguages();
    } else {
      dispatch(translationActions.setTargetLanguage(language));
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 1, mb: 1 }}>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link to="/">{t('translation.navigation.home')}</Link>
        <Link to="/translate-tasks">
          {t('translation.navigation.translateTasks')}
        </Link>
        <Typography color="textPrimary">
          {t('translation.navigation.new')}
        </Typography>
      </Breadcrumbs>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 2 }}>
          <Button
            variant={translation.mode === 'Text' ? 'contained' : 'outlined'}
            onClick={() => dispatch(translationActions.setMode('Text'))}
            startIcon={<TranslateIcon />}
            sx={{ textTransform: 'none' }}
          >
            {t('translation.mode.text')}
          </Button>
          <Button
            variant={
              translation.mode === 'Documents' ? 'contained' : 'outlined'
            }
            onClick={() => dispatch(translationActions.setMode('Documents'))}
            startIcon={<DescriptionIcon />}
            sx={{ ml: 2, textTransform: 'none' }}
          >
            {t('translation.mode.documents')}
          </Button>
        </Box>
        <Grid container alignItems="center" spacing={1}>
          <Grid size={5}>
            <LanguageTabs
              languages={translation.sourceLanguages}
              selectedLanguage={translation.sourceLanguage}
              setSelectedLanguage={handleSourceLanguageChange}
              anchorEl={anchorElSource}
              setAnchorEl={setAnchorElSource}
              disabled={composeState.isGenerating}
            />
          </Grid>
          <Grid size={1}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <IconButton
                onClick={handleSwapLanguages}
                disabled={composeState.isGenerating}
              >
                <SwapHorizIcon />
              </IconButton>
            </Box>
          </Grid>
          <Grid size={6}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <LanguageTabs
                languages={translation.targetLanguages}
                selectedLanguage={translation.targetLanguage}
                setSelectedLanguage={handleTargetLanguageChange}
                anchorEl={anchorElTarget}
                setAnchorEl={setAnchorElTarget}
                disabled={composeState.isGenerating}
              />
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Badge
                  badgeContent={translation.selectedGlossaries.length}
                  color="error"
                >
                  <Button
                    onClick={handleGlossaryButtonClick}
                    variant="outlined"
                    sx={{ ml: 2, textTransform: 'none' }}
                  >
                    {t('translation.buttons.glossary')}
                  </Button>
                </Badge>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Badge
                  badgeContent={translation.selectedReferenceStores.length}
                  color="error"
                >
                  <Button
                    onClick={handleReferenceStoreButtonClick}
                    variant="outlined"
                    sx={{ ml: 2, textTransform: 'none' }}
                  >
                    {t('translation.buttons.reference')}
                  </Button>
                </Badge>
              </Box>
            </Box>
          </Grid>
        </Grid>
        {translation.mode === 'Text' ? (
          <TextTranslateArea
            sourceLanguage={translation.sourceLanguage}
            targetLanguage={translation.targetLanguage}
          />
        ) : (
          <DocumentTranslateArea
            translation={translation}
            handleDrop={handleDrop}
            handleDragOver={handleDragOver}
            handleFileChange={handleFileChange}
            handleTranslate={handleTranslate}
            handleRemoveFile={() => dispatch(translationActions.removeFile())}
            formatFileSize={formatFileSize}
          />
        )}
      </Paper>

      <GlossaryDialog
        open={translation.glossaryDialogOpen}
        onClose={handleGlossaryDialogClose}
        onApply={handleGlossaryApply}
      />

      <ReferenceStoreDialog
        open={translation.referenceStoreDialogOpen}
        onClose={handleReferenceStoreDialogClose}
        onApply={handleReferenceStoreApply}
      />

      <Dialog open={blocker.state === 'blocked'}>
        <DialogTitle>{t('translation.dialog.title')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('translation.dialog.content')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => blocker.proceed()}>
            {t('translation.dialog.leave')}
          </Button>
          <Button
            onClick={() => blocker.reset()}
            color="primary"
            variant="contained"
            autoFocus
          >
            {t('translation.dialog.stay')}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};
