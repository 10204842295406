/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate } from 'react-router-dom';
import TranslateIcon from '@mui/icons-material/Translate';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import HomeIcon from '@mui/icons-material/Home';
import CableIcon from '@mui/icons-material/Cable';
import LibraryBooks from '@mui/icons-material/LibraryBooks';
import EditRoad from '@mui/icons-material/EditRoad';
import RuleFolderIcon from '@mui/icons-material/RuleFolder';
import { Collapse, Divider, List } from '@mui/material';
import { ExpandLess, ExpandMore, Tune } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@ink-ai/portal/reducers';
import RuleIcon from '@mui/icons-material/Rule';
import {
  ASSISTANT_TABS,
  aiAssistant,
} from '@ink-ai/portal/reducers/ai-assistant';
import { inspectActions } from '@ink-ai/portal/reducers/inspect';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import { useTranslation } from 'react-i18next';

export const MainListItems = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [customOpen, setCustomOpen] = React.useState(true);
  const appState = useSelector((state: RootState) => state.app);
  const dispatch = useDispatch<any>();

  const handleClick = () => {
    setCustomOpen(!customOpen);
  };

  return (
    <React.Fragment>
      <ListItemButton onClick={() => navigate('/')}>
        <ListItemIcon>
          <HomeIcon />
        </ListItemIcon>
        <ListItemText primary={t('common.home')} />
      </ListItemButton>
      <ListItemButton onClick={() => navigate('/articles')}>
        <ListItemIcon>
          <HistoryEduIcon />
        </ListItemIcon>
        <ListItemText primary={t('navigation.medicalWriter')} />
      </ListItemButton>
      <ListItemButton onClick={() => navigate('/translate-tasks')}>
        <ListItemIcon>
          <TranslateIcon />
        </ListItemIcon>
        <ListItemText primary={t('navigation.medicalTranslator')} />
      </ListItemButton>
      <ListItemButton
        onClick={() => {
          dispatch(aiAssistant.actions.setCurrentTab(ASSISTANT_TABS.INSPECT));
          dispatch(inspectActions.changeInspectType('rule'));
          navigate('/articles/new');
        }}
      >
        <ListItemIcon>
          <RuleIcon />
        </ListItemIcon>
        <ListItemText primary={t('navigation.ruleChecking')} />
      </ListItemButton>
      <ListItemButton
        onClick={() => {
          dispatch(aiAssistant.actions.setCurrentTab(ASSISTANT_TABS.INSPECT));
          dispatch(inspectActions.changeInspectType('fact'));
          navigate('/articles/new');
        }}
      >
        <ListItemIcon>
          <FactCheckOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary={t('navigation.factChecking')} />
      </ListItemButton>
      <Divider />
      <ListItemButton onClick={handleClick}>
        {appState.leftDrawerOpen ? (
          <ListItemText primary={t('navigation.customization')} />
        ) : (
          <ListItemIcon>
            <Tune />
          </ListItemIcon>
        )}
        {appState.leftDrawerOpen &&
          (customOpen ? <ExpandLess /> : <ExpandMore />)}
      </ListItemButton>
      <Collapse in={customOpen} timeout="auto" unmountOnExit>
        <List
          sx={appState.leftDrawerOpen ? { pl: 2 } : {}}
          component="div"
          disablePadding
        >
          <ListItemButton onClick={() => navigate('/glossary')}>
            <ListItemIcon>
              <LocalLibraryIcon />
            </ListItemIcon>
            <ListItemText primary={t('navigation.glossary')} />
          </ListItemButton>
          <ListItemButton onClick={() => navigate('/reference-store')}>
            <ListItemIcon>
              <LibraryBooks />
            </ListItemIcon>
            <ListItemText primary={t('navigation.referenceStore')} />
          </ListItemButton>
          <ListItemButton onClick={() => navigate('/writing-instruction')}>
            <ListItemIcon>
              <EditRoad />
            </ListItemIcon>
            <ListItemText primary={t('navigation.writingInstruction')} />
          </ListItemButton>
          <ListItemButton onClick={() => navigate('/writing-rule')}>
            <ListItemIcon>
              <RuleFolderIcon />
            </ListItemIcon>
            <ListItemText primary={t('navigation.writingRule')} />
          </ListItemButton>
        </List>
      </Collapse>
      <Divider />
      <ListItemButton onClick={() => navigate('/settings/connectors')}>
        <ListItemIcon>
          <CableIcon />
        </ListItemIcon>
        <ListItemText primary={t('navigation.connectors')} />
      </ListItemButton>
      <ListItemButton onClick={() => navigate('/settings')}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary={t('common.settings')} />
      </ListItemButton>
    </React.Fragment>
  );
};
