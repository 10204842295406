/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Container,
  Paper,
  TextField,
  Typography,
  Breadcrumbs,
  Tabs,
  Tab,
  Button,
  Alert,
  Link,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigationListener } from '../../components/hooks/useNavigationListener';
import { useNavigate } from 'react-router-dom';
import { RootState } from '@ink-ai/portal/reducers';
import { useDispatch, useSelector } from 'react-redux';
import {
  connectorActions,
  importConnector,
} from '@ink-ai/portal/reducers/connector';

export const ImportConnector: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const connector = useSelector((state: RootState) => state.connector);
  const [tabValue, setTabValue] = useState(1);
  const [lambdaName, setLambdaName] = useState('');

  useNavigationListener();

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLambdaName(e.target.value);
  };

  const handleCancel = () => {
    navigate('/settings/connectors');
  };

  const handleImport = () => {
    dispatch(connectorActions.setLoading(true));
    dispatch(importConnector(lambdaName));
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 1, mb: 1 }}>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Typography color="textPrimary">
          {t('connectors.navigation.settings')}
        </Typography>
        <Typography color="textPrimary">
          {t('connectors.navigation.connectors')}
        </Typography>
      </Breadcrumbs>
      <Paper sx={{ p: 2 }}>
        <Typography variant="h5" sx={{ textAlign: 'left', mb: 2 }}>
          {t('connectors.import.title')}
        </Typography>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="connector tabs"
        >
          <Tab label={t('connectors.import.tabs.searchEngine')} />
          <Tab label={t('connectors.import.tabs.knowledgeBase')} />
        </Tabs>
        {tabValue === 0 && (
          <Box sx={{ mt: 2, textAlign: 'left' }}>
            <Alert
              severity="info"
              sx={{
                mb: 2,
                display: 'flex',
                alignItems: 'right',
                width: '60%',
                border: '1px solid #2196f3',
                backgroundColor: '#fff',
                paddingRight: '40px',
              }}
            >
              <Box>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  {t('connectors.import.searchEngine.title')}
                </Typography>
                <Typography variant="body2">
                  {t('connectors.import.searchEngine.description')}
                </Typography>
              </Box>
            </Alert>
            <TextField
              label={t('connectors.import.searchEngine.lambdaLabel')}
              variant="outlined"
              value={lambdaName}
              onChange={handleInputChange}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ width: '60%', mt: 1 }}
            />
            <Box
              sx={{
                mt: 2,
                textAlign: 'left',
              }}
            >
              <LoadingButton
                variant="contained"
                color="primary"
                onClick={handleImport}
                disabled={!lambdaName}
                sx={{ width: '99px' }}
                loading={connector.loading}
              >
                {t('connectors.import.buttons.import')}
              </LoadingButton>
              <Button onClick={handleCancel} sx={{ ml: 1, width: '99px' }}>
                {t('connectors.import.buttons.cancel')}
              </Button>
            </Box>
          </Box>
        )}
        {tabValue === 1 && (
          <Box sx={{ mt: 2, textAlign: 'left' }}>
            <Alert
              severity="info"
              sx={{
                mb: 2,
                display: 'flex',
                alignItems: 'right',
                width: '60%',
                border: '1px solid #2196f3',
                backgroundColor: '#fff',
                paddingRight: '40px',
              }}
            >
              <Box>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  {t('connectors.import.knowledgeBase.title')}
                </Typography>
                <Typography variant="body2">
                  {t('connectors.import.knowledgeBase.description')}
                </Typography>
              </Box>
            </Alert>
            <TextField
              label={t('connectors.import.knowledgeBase.lambdaLabel')}
              variant="outlined"
              value={lambdaName}
              onChange={handleInputChange}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ width: '60%', mt: 1 }}
            />
            <Box
              sx={{
                mt: 2,
                textAlign: 'left',
              }}
            >
              <LoadingButton
                variant="contained"
                color="primary"
                onClick={handleImport}
                disabled={!lambdaName}
                sx={{ width: '99px' }}
                loading={connector.loading}
              >
                {t('connectors.import.buttons.import')}
              </LoadingButton>
              <Button onClick={handleCancel} sx={{ ml: 1, width: '99px' }}>
                {t('connectors.import.buttons.cancel')}
              </Button>
            </Box>
          </Box>
        )}
      </Paper>
    </Container>
  );
};
