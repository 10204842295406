/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { GlossaryItemResponseDto, Language } from '@ink-ai/insight-service-sdk';

interface CreateGlossaryItemDialogProps {
  open: boolean;
  onClose: () => void;
  onCreate: (item: any) => void;
  editingItem?: GlossaryItemResponseDto;
}

export const CreateUpdateGlossaryItemDialog: React.FC<
  CreateGlossaryItemDialogProps
> = ({ open, onClose, onCreate, editingItem }) => {
  const { t } = useTranslation('translation');
  const [newItem, setNewItem] = useState({
    name: '',
    desc: '',
    abbr: '',
    trans: [{ language: '', translation: '' }],
  });

  const [errors, setErrors] = useState({
    name: false,
    trans: false,
  });

  useEffect(() => {
    if (open) {
      setErrors({ name: false, trans: false });
      if (editingItem) {
        const { name = '', desc = '', abbr = '', translations } = editingItem;
        setNewItem({
          name,
          desc,
          abbr,
          trans: translations
            ? Object.values(translations).map(({ language, translation }) => ({
                language,
                translation,
              }))
            : [{ language: '', translation: '' }],
        });
      } else {
        setNewItem({
          name: '',
          desc: '',
          abbr: '',
          trans: [{ language: '', translation: '' }],
        });
      }
    }
  }, [open, editingItem]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewItem({ ...newItem, [name]: value });
    if (name === 'name' && value.trim() !== '') {
      setErrors((prev) => ({ ...prev, name: false }));
    }
  };

  const handleTranslationChange = (index, key, value) => {
    const updatedTranslations = newItem.trans.map((translation, i) =>
      i === index ? { ...translation, [key]: value } : translation,
    );
    setNewItem({ ...newItem, trans: updatedTranslations });

    if (key === 'language' && value.trim() !== '') {
      setErrors((prev) => ({ ...prev, trans: false }));
    }
  };

  const handleAddTranslation = () => {
    setNewItem({
      ...newItem,
      trans: [...newItem.trans, { language: '', translation: '' }],
    });
  };

  const handleRemoveTranslation = (index) => {
    const updatedTranslations = newItem.trans.filter((_, i) => i !== index);
    setNewItem({ ...newItem, trans: updatedTranslations });
  };

  const handleSubmit = () => {
    const isNameValid = newItem.name.trim() !== '';
    const isTransValid = newItem.trans.some(
      (translation) => translation.language.trim() !== '',
    );

    setErrors({
      name: !isNameValid,
      trans: !isTransValid,
    });

    if (!isNameValid || !isTransValid) return;

    onCreate(newItem);
    setNewItem({
      name: '',
      desc: '',
      abbr: '',
      trans: [{ language: '', translation: '' }],
    });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {editingItem
          ? t('glossary.dialog.editItem')
          : t('glossary.dialog.newItem')}
      </DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          variant="standard"
          label={t('glossary.dialog.form.name.label')}
          type="text"
          fullWidth
          name="name"
          helperText={
            errors.name
              ? t('glossary.dialog.form.name.required')
              : t('glossary.dialog.form.name.helper')
          }
          error={errors.name}
          value={newItem.name}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          variant="standard"
          label={t('glossary.dialog.form.description.label')}
          type="text"
          fullWidth
          name="desc"
          helperText={t('glossary.dialog.form.description.helper')}
          value={newItem.desc}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          variant="standard"
          label={t('glossary.dialog.form.abbreviation.label')}
          type="text"
          fullWidth
          name="abbr"
          helperText={t('glossary.dialog.form.abbreviation.helper')}
          value={newItem.abbr}
          onChange={handleChange}
        />
        {newItem.trans.map((translation, index) => (
          <div
            key={index}
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '8px',
            }}
          >
            <FormControl
              variant="standard"
              style={{ marginRight: '8px', minWidth: 120 }}
              error={errors.trans}
            >
              <InputLabel>
                {t('glossary.dialog.form.language.label')}
              </InputLabel>
              <Select
                value={translation.language}
                onChange={(e) =>
                  handleTranslationChange(index, 'language', e.target.value)
                }
              >
                <MenuItem value={Language.ZhCn}>
                  {t('glossary.dialog.languages.chinese')}
                </MenuItem>
                <MenuItem value={Language.EnUs}>
                  {t('glossary.dialog.languages.english')}
                </MenuItem>
                <MenuItem value={Language.Ja}>
                  {t('glossary.dialog.languages.japanese')}
                </MenuItem>
              </Select>
            </FormControl>
            <TextField
              margin="dense"
              variant="standard"
              label={t('glossary.dialog.form.translation')}
              type="text"
              fullWidth
              value={translation.translation}
              onChange={(e) =>
                handleTranslationChange(index, 'translation', e.target.value)
              }
            />
            <IconButton
              onClick={() => handleRemoveTranslation(index)}
              style={{ marginLeft: '8px' }}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        ))}
        {errors.trans && (
          <p style={{ color: 'red', marginBottom: '8px' }}>
            {t('glossary.dialog.form.language.required')}
          </p>
        )}
        <Button
          startIcon={<AddIcon />}
          onClick={handleAddTranslation}
          style={{ marginTop: '8px' }}
        >
          {t('glossary.dialog.buttons.addTranslation')}
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('glossary.dialog.buttons.cancel')}</Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          {editingItem
            ? t('glossary.dialog.buttons.update')
            : t('glossary.dialog.buttons.create')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
