/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Menu,
  MenuItem,
  Breadcrumbs,
  Link,
  Grid,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { RootState } from '../../reducers';
import {
  glossaryActions,
  createGlossaryItem,
  updateGlossaryItem,
  removeGlossaryItems,
} from '../../reducers/glossary';
import { CreateUpdateGlossaryItemDialog } from '../components/glossary/CreateUpdateGlossaryItemDialog';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  CreateGlossaryItemDto,
  GlossaryCollectionDtoStatusEnum,
  GlossaryCollectionDtoTypeEnum,
  GlossaryItemResponseDto,
  UpdateGlossaryItemDto,
} from '@ink-ai/insight-service-sdk';
import { GlossaryItemTable } from '../components/glossary/GlossaryItemTable';
import { formatDate } from '@ink-ai/portal/common/utils';

export const GlossaryItemList: React.FC = () => {
  const { t } = useTranslation();
  const formatter = new Intl.NumberFormat('en-US');
  const dispatch = useDispatch<any>();
  const { id: glossaryCollectionUuid } = useParams<{ id: string }>();
  const glossary = useSelector((state: RootState) => state.glossary);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [editingItem, setEditingItem] =
    useState<GlossaryItemResponseDto | null>(null);
  const actionsButtonRef = useRef<HTMLButtonElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    handleMenuClose();
    if (glossaryCollectionUuid) {
      dispatch(glossaryActions.setLoading(true));
      dispatch(
        removeGlossaryItems({
          glossaryUuid: glossaryCollectionUuid,
          itemUuids: selectedItems,
        }),
      );
      setSelectedItems([]);
    }
  };

  const handleCreate = async (newGlossaryItem: CreateGlossaryItemDto) => {
    if (glossaryCollectionUuid) {
      dispatch(glossaryActions.setLoading(true));
      setOpen(false);
      handleMenuClose();
      await dispatch(
        createGlossaryItem({
          glossaryUuid: glossaryCollectionUuid,
          item: newGlossaryItem,
        }),
      );
    }
  };

  const handleEdit = async (newGlossaryItem: UpdateGlossaryItemDto) => {
    if (glossaryCollectionUuid && editingItem) {
      dispatch(glossaryActions.setLoading(true));
      setOpen(false);
      setEditingItem(null);
      handleMenuClose();
      await dispatch(
        updateGlossaryItem({
          glossaryUuid: glossaryCollectionUuid,
          itemUuid: editingItem.uuid,
          item: newGlossaryItem,
        }),
      );
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const handleEditClick = () => {
    const itemToEdit = glossary.glossaryItems.find(
      (item) => item.uuid === selectedItems[0],
    );
    if (itemToEdit) {
      setEditingItem(itemToEdit);
      setOpen(true);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 1, mb: 1 }}>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Link
          color="inherit"
          onClick={() => navigate('/')}
          sx={{ textDecoration: 'none', cursor: 'pointer' }}
        >
          {t('glossary.items.navigation.home')}
        </Link>
        <Link
          color="inherit"
          onClick={() => navigate('/glossary')}
          sx={{ textDecoration: 'none', cursor: 'pointer' }}
        >
          {t('glossary.items.navigation.glossary')}
        </Link>
        <Typography color="textPrimary">
          {glossary.selectedGlossary?.name ||
            t('glossary.items.navigation.loading')}
        </Typography>
      </Breadcrumbs>

      {glossary.selectedGlossary?.type ===
      GlossaryCollectionDtoTypeEnum.External ? (
        <Paper sx={{ p: 3, display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h5" sx={{ textAlign: 'left' }}>
            {glossary.selectedGlossary?.name ||
              t('glossary.items.navigation.loading')}
          </Typography>
          <Typography variant="subtitle1" sx={{ textAlign: 'left', mb: 2 }}>
            {t('glossary.items.settings.title')}
          </Typography>

          <Grid container spacing={3} sx={{ mb: 3 }}>
            <Grid item xs={4}>
              <Typography
                variant="body2"
                sx={{ fontWeight: 'bold', textAlign: 'left' }}
              >
                {t('glossary.items.settings.terms')}
              </Typography>
              <Typography
                variant="body2"
                sx={{ textAlign: 'left', color: 'gray' }}
              >
                {glossary.selectedGlossary?.status ===
                GlossaryCollectionDtoStatusEnum.Ready
                  ? formatter.format(glossary.selectedGlossary?.itemCount)
                  : t('glossary.items.settings.na')}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography
                variant="body2"
                sx={{ fontWeight: 'bold', textAlign: 'left' }}
              >
                {t('glossary.items.settings.status')}
              </Typography>
              <Typography
                variant="body2"
                sx={{ textAlign: 'left', color: 'gray' }}
              >
                {glossary.selectedGlossary?.status.charAt(0).toUpperCase() +
                  glossary.selectedGlossary?.status.slice(1).toLowerCase()}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography
                variant="body2"
                sx={{ fontWeight: 'bold', textAlign: 'left' }}
              >
                {t('glossary.items.settings.storage')}
              </Typography>
              <Typography
                variant="body2"
                sx={{ textAlign: 'left', color: 'gray' }}
              >
                {t('glossary.items.settings.openSearch')}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography
                variant="body2"
                sx={{ fontWeight: 'bold', textAlign: 'left' }}
              >
                {t('glossary.items.settings.indexName')}
              </Typography>
              <Typography
                variant="body2"
                sx={{ textAlign: 'left', color: 'gray' }}
              >
                {glossary.selectedGlossary?.externalGlossary?.indexName ||
                  t('glossary.items.settings.na')}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography
                variant="body2"
                sx={{ fontWeight: 'bold', textAlign: 'left' }}
              >
                {t('glossary.items.settings.updatedAt')}
              </Typography>
              <Typography
                variant="body2"
                sx={{ textAlign: 'left', color: 'gray' }}
              >
                {formatDate(glossary.selectedGlossary?.updatedAt)}
              </Typography>
            </Grid>
            <Grid item xs={4}></Grid>
          </Grid>
        </Paper>
      ) : (
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h5" sx={{ textAlign: 'left', mb: 2 }}>
            {glossary.selectedGlossary?.name ||
              t('glossary.items.navigation.loading')}
          </Typography>
          <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }}>
            <TextField
              label={t('glossary.search.label')}
              variant="outlined"
              size="small"
              value={searchTerm}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleSearchChange}
            />
            <Box>
              <Button
                ref={actionsButtonRef}
                color="primary"
                onClick={handleMenuOpen}
                endIcon={<ArrowDropDownIcon />}
                variant="outlined"
                sx={{
                  borderColor: 'black',
                  color: 'black',
                  '&:hover': {
                    borderColor: 'black',
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  },
                }}
              >
                {t('glossary.items.actions.button')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{ ml: 1 }}
                onClick={() => setOpen(true)}
              >
                {t('glossary.items.actions.create')}
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                slotProps={{
                  paper: {
                    style: {
                      minWidth: actionsButtonRef.current?.offsetWidth,
                    },
                  },
                }}
              >
                <MenuItem
                  onClick={handleEditClick}
                  disabled={selectedItems.length !== 1}
                >
                  {t('glossary.items.actions.edit')}
                </MenuItem>
                <MenuItem
                  onClick={handleDelete}
                  disabled={selectedItems.length === 0}
                >
                  {t('glossary.items.actions.delete')}
                </MenuItem>
              </Menu>
            </Box>
          </Box>
          {glossaryCollectionUuid && (
            <GlossaryItemTable
              glossaryId={glossaryCollectionUuid}
              selectedItems={selectedItems}
              onSelectedItemsChange={setSelectedItems}
              searchTerm={searchTerm}
              page={page}
              onPageChange={setPage}
            />
          )}
        </Paper>
      )}

      <CreateUpdateGlossaryItemDialog
        open={open}
        onClose={() => {
          setOpen(false);
          setEditingItem(null);
        }}
        onCreate={editingItem ? handleEdit : handleCreate}
        editingItem={editingItem}
      />
    </Container>
  );
};
